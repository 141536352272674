import { MaimaStore } from './maima-store';

export class RootStore{
    maimaStore: MaimaStore;

    constructor(){
        this.maimaStore = new MaimaStore();
    }
}

export const store = new RootStore();