// src/apiClient.js
import axios, { AxiosInstance } from 'axios';
import configFile from '../Config';

const axiosClient = (token: string | null = null): AxiosInstance => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    : {
        'Content-Type': 'application/json',
      };

  const client = axios.create({
    baseURL: configFile.siteURL,
    headers,
    timeout: 60000,
    withCredentials: false,
  });

  client.interceptors.request.use(
    (config: any) => {
      config.headers = config.headers || {};
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => {
      if (response.status === 200) {
        return response;
      } else {
        const messages = response.data.messages;
        if (messages) {
          if (Array.isArray(messages)) {
            return Promise.reject({ messages });
          }
          return Promise.reject({ messages: [messages] });
        }
        return Promise.reject({ messages: ['got errors'] });
      }
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('ACCESS_TOKEN');
      } else if (error.response && error.response.status === 500) {
        return Promise.reject(error.response);
      } else return Promise.reject(error);
    }
  );

  return client;
};

export default axiosClient;
