import React, { useRef, useEffect, useState } from 'react';
import * as BlocklyCore from 'blockly/core';
import 'blockly/blocks';
import * as locale from 'blockly/msg/en';
import './BlocklyComponent.scss';
import {javascriptGenerator, Order} from 'blockly/javascript';
import { Box, Grid, Button, Typography, Paper } from '@mui/material';

BlocklyCore.setLocale(locale);  


const BlocklyComponent = (props: any) => {
    const blocklyDiv = useRef<HTMLDivElement>(null);
    const toolbox = useRef<HTMLDivElement>(null);
    let worcspaceTest:any=null;
    let primaryWorkspace = useRef<BlocklyCore.WorkspaceSvg>();
    let isAdd = false;
    const [generatedCode, setGeneratedCode] = useState<string>("");

    const generateCode = () => {
      var code = javascriptGenerator.workspaceToCode(primaryWorkspace.current);
      console.log(code);
      setGeneratedCode(code);
    };
    

    javascriptGenerator.forBlock['string_length'] = function(block) {
      const value = javascriptGenerator.valueToCode(block, 'VALUE', Order.ATOMIC) || "''";
      const code = `${value}.length`;
      return [code, Order.MEMBER];
    };

    javascriptGenerator.forBlock['if_return_error'] = function(block) {
      var value = javascriptGenerator.valueToCode(block, 'SET_VALUE', Order.ATOMIC);
      var code = `if (${value} < 0) {\n  return -1;\n} else {\n return ${value};\n}\n`;
      return code;
    };

    javascriptGenerator.forBlock['take_photo'] = function(block) {
      var statements_code = javascriptGenerator.statementToCode(block, 'CODE');
      var code = `function take_photo() {\n${statements_code}}\n`;
      return code;
    }

    javascriptGenerator.forBlock['function_flash_photo'] = function(block) {
      var statements_code = javascriptGenerator.statementToCode(block, 'CODE');
      var code = `function flash_photo() {\n${statements_code}}\n`;
      return code;
    }

    javascriptGenerator.forBlock['make_photo'] = function(block) {
      var value_photo = block.getFieldValue('PHOTO');
      var code = `camera.photo(${value_photo})`;
      return [code, Order.NONE];
    };

    javascriptGenerator.forBlock['flash_photo'] = function(block) {
      var code = 'camera.flash();\n';
      return code;
    };

    javascriptGenerator.forBlock['robot_move'] = function(block) {
      var x_coordinate = block.getFieldValue('X_COORDINATE');
      var y_coordinate = block.getFieldValue('Y_COORDINATE');
      var z_coordinate = block.getFieldValue('Z_COORDINATE');
      var code = `robot.go(${x_coordinate}, ${y_coordinate}, ${z_coordinate});\n`;
      return code;
    };

    javascriptGenerator.forBlock['robot_take'] = function(block) {
      var variable_name = javascriptGenerator.nameDB_?.getName(block.getFieldValue('NAME'), BlocklyCore.VARIABLE_CATEGORY_NAME);
      var code = `robot.take(${variable_name});\n`;
      return code;
    };

    javascriptGenerator.forBlock['robot_release'] = function(block) {
      var code = `robot.release();\n`;
      return code;
    };

    useEffect(() => {
      if (blocklyDiv.current && toolbox.current && !isAdd){
        const {initialXml, children, ...rest} = props;
        primaryWorkspace.current = BlocklyCore.inject(blocklyDiv.current, {
          toolbox: toolbox.current,
          ...rest,
        });
    
  
        if (initialXml) {
          BlocklyCore.Xml.domToWorkspace(
            BlocklyCore.utils.xml.textToDom(initialXml),
            primaryWorkspace.current,
          );
        }
        isAdd = true;
      }
    }, [primaryWorkspace, toolbox, blocklyDiv, props]);

    function loadWorkspace(event: any) {
      const workspace = BlocklyCore.getMainWorkspace();
      if (worcspaceTest) {
        BlocklyCore.serialization.workspaces.load(worcspaceTest, workspace);
      } else {
        workspace.clear();
      }
    }
    
    return(
      <Box sx={{height:"100vh", width:"100vw"}}>
        <Button variant='contained' id="buttonAction" onClick={generateCode}>Convert to JS</Button>
        <Button variant='contained' id="buttonAction" onClick={(event) => {worcspaceTest = BlocklyCore.serialization.workspaces.save(BlocklyCore.getMainWorkspace()); console.log(worcspaceTest);}}>Save workspace</Button>
        <Button variant='contained' id="buttonAction" onClick={loadWorkspace}>Load workspace</Button>
        <Grid id="combineDiv" container spacing={1}>
          <Grid item xs={8} ref={blocklyDiv} id="blocklyDiv" />
          <Box style={{display: 'none'}} ref={toolbox}>
            {props.children}      
          </Box>
          <Grid item xs={4} id="generatedDiv">
            <Paper elevation={3} id="generatedPaper">
              <Typography variant="body1" component="pre">
                {generatedCode}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
}

export default BlocklyComponent;