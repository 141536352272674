import React from 'react';
import { 
    Box,
    Button,
    Container,
    Typography 
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import Directions from '../enums/enum-directions';
import "../App.scss";

function Main() {
    const navigate = useNavigate();

    const navigateToPage = (endpoint: string) => {
        navigate(endpoint, { replace: true });
    }

    return(
        <Container>
            <Button onClick={() => navigateToPage(Directions.Blockly)} variant='contained' size='large' className='button-main'>Blockly</Button>   
            <Button onClick={() => navigateToPage(Directions.Maima)} variant='contained' size='large' className='button-main'>Maima</Button>
        </Container>
    );
}

export default Main;