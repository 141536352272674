import { observable, action, makeAutoObservable, computed } from 'mobx';
import axiosClient from "../services/axiosInstance";
import axios, { AxiosInstance } from 'axios';

export interface IPicture{
    id: number;
    tags: string;
    filename:string;
    conditions: string;
    createdAt: string;
    updatedAt: string;
}

export interface IShape{
    id: number;
    pictureId: number;
    shape: number;
    dimensions: string;
    extra: string;
    createdAt: string;
    updatedAt: string;
}

export class  MaimaStore{

    constructor() {   
        makeAutoObservable(this, { });
    }

    getPictures = action(async() => {
        let listPictures: IPicture[] = [];

        try{
            const response = await this.client.get("/pictures");
            response.data.items.forEach((value:any)=>{
                let picture: IPicture = {
                    id: value.id,
                    tags: value.tags,
                    filename: value.filename,
                    conditions: value.conditions,
                    createdAt: value.created_at,
                    updatedAt: value.updated_at
                };

                listPictures.splice(0, 0, picture);
            });
        } catch (error) {
            console.error("Error fetching pictures:", error);
        }

        return listPictures;
    })
    
    getListOfShapesById = async(id: number) => {
        let listShapes: IShape[] = [];
        try{
            const response = await this.client.get(`/pictures/${id}/shapes`);
            response.data.items.forEach((value:any)=>{
                let shape: IShape = {
                    id: value.id,
                    pictureId: value.picture_id,
                    dimensions: value.dimensions,
                    extra: value.extra,
                    shape: value.shape,
                    createdAt: value.created_at,
                    updatedAt: value.updated_at
                };

                listShapes.splice(0, 0, shape);
            });
        } catch (error) {
            console.error("Error fetching list of shapes:", error);
        }
        return listShapes;
    }

    get client() {
        return axiosClient();
    }
}