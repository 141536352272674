import React from 'react';
import logo from './logo.svg';
import '../App.scss';
import BlocklyComponent, {Block, Value, Field, Shadow, Category, Next, Statement, Mutation, Separator} from '../Blockly';
import '../blocks/custom-blocks';
import 'blockly/blocks';
import 'blockly/javascript';

function App(props: any) {
  

  return (
    <BlocklyComponent
      trashcan={true}
      move={{
        scrollbars: true,
      }}
      initialXml={`
        <xml xmlns="http://www.w3.org/1999/xhtml">
        </xml>
      `}>
        <Category name="Logic" colour="#5b80a5">
    <Block type="controls_if"></Block>
    <Block type="logic_compare">
      <Field name="OP">EQ</Field>
    </Block>
    <Block type="logic_operation">
      <Field name="OP">AND</Field>
    </Block>
    <Block type="logic_negate"></Block>
    <Block type="logic_boolean">
      <Field name="BOOL">TRUE</Field>
    </Block>
    <Block type="logic_null"></Block>
    <Block type="logic_ternary"></Block>
  </Category>
  <Category name="Math" colour="#5b67a5">
    <Block type="math_number">
      <Field name="NUM">0</Field>
    </Block>
    <Block type="math_arithmetic">
      <Field name="OP">ADD</Field>
      <Value name="A">
        <Shadow type="math_number">
          <Field name="NUM">1</Field>
        </Shadow>
      </Value>
      <Value name="B">
        <Shadow type="math_number">
          <Field name="NUM">1</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_single">
      <Field name="OP">ROOT</Field>
      <Value name="NUM">
        <Shadow type="math_number">
          <Field name="NUM">9</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_trig">
      <Field name="OP">SIN</Field>
      <Value name="NUM">
        <Shadow type="math_number">
          <Field name="NUM">45</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_constant">
      <Field name="CONSTANT">PI</Field>
    </Block>
    <Block type="math_number_property">
      <Mutation divisor_input="false"></Mutation>
      <Field name="PROPERTY">EVEN</Field>
      <Value name="NUMBER_TO_CHECK">
        <Shadow type="math_number">
          <Field name="NUM">0</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_round">
      <Field name="OP">ROUND</Field>
      <Value name="NUM">
        <Shadow type="math_number">
          <Field name="NUM">3.1</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_on_list">
      <Mutation op="SUM"></Mutation>
      <Field name="OP">SUM</Field>
    </Block>
    <Block type="math_modulo">
      <Value name="DIVIDEND">
        <Shadow type="math_number">
          <Field name="NUM">64</Field>
        </Shadow>
      </Value>
      <Value name="DIVISOR">
        <Shadow type="math_number">
          <Field name="NUM">10</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_constrain">
      <Value name="Value">
        <Shadow type="math_number">
          <Field name="NUM">50</Field>
        </Shadow>
      </Value>
      <Value name="LOW">
        <Shadow type="math_number">
          <Field name="NUM">1</Field>
        </Shadow>
      </Value>
      <Value name="HIGH">
        <Shadow type="math_number">
          <Field name="NUM">100</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_random_int">
      <Value name="FROM">
        <Shadow type="math_number">
          <Field name="NUM">1</Field>
        </Shadow>
      </Value>
      <Value name="TO">
        <Shadow type="math_number">
          <Field name="NUM">100</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="math_random_float"></Block>
  </Category>
  <Category name="Text" colour="#9fa55b">
    <Block type="text">
      <Field name="TEXT"></Field>
    </Block>
    <Block type="text_join">
      <Mutation items="2"></Mutation>
    </Block>
    <Block type="text_append">
      <Field name="VAR" id="IIY2#=qg`bN(n9~0IU*.">item</Field>
      <Value name="TEXT">
        <Shadow type="text">
          <Field name="TEXT"></Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_length">
      <Value name="Value">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_isEmpty">
      <Value name="Value">
        <Shadow type="text">
          <Field name="TEXT"></Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_indexOf">
      <Field name="END">FIRST</Field>
      <Value name="Value">
        <Block type="variables_get">
          <Field name="VAR" id="L2GHbv|o@U~ys5h3hMsl">text</Field>
        </Block>
      </Value>
      <Value name="FIND">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_charAt">
      <Mutation at="true"></Mutation>
      <Field name="WHERE">FROM_START</Field>
      <Value name="Value">
        <Block type="variables_get">
          <Field name="VAR" id="L2GHbv|o@U~ys5h3hMsl">text</Field>
        </Block>
      </Value>
    </Block>
    <Block type="text_getSubstring">
      <Mutation at1="true" at2="true"></Mutation>
      <Field name="WHERE1">FROM_START</Field>
      <Field name="WHERE2">FROM_START</Field>
      <Value name="STRING">
        <Block type="variables_get">
          <Field name="VAR" id="L2GHbv|o@U~ys5h3hMsl">text</Field>
        </Block>
      </Value>
    </Block>
    <Block type="text_changeCase">
      <Field name="CASE">UPPERCASE</Field>
      <Value name="TEXT">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_trim">
      <Field name="MODE">BOTH</Field>
      <Value name="TEXT">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_print">
      <Value name="TEXT">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="text_prompt_ext">
      <Mutation type="TEXT"></Mutation>
      <Field name="TYPE">TEXT</Field>
      <Value name="TEXT">
        <Shadow type="text">
          <Field name="TEXT">abc</Field>
        </Shadow>
      </Value>
    </Block>
  </Category>
  <Category name="Lists" colour="#745ba5">
    <Block type="lists_create_with">
      <Mutation items="0"></Mutation>
    </Block>
    <Block type="lists_create_with">
      <Mutation items="3"></Mutation>
    </Block>
    <Block type="lists_repeat">
      <Value name="NUM">
        <Shadow type="math_number">
          <Field name="NUM">5</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="lists_length"></Block>
    <Block type="lists_isEmpty"></Block>
    <Block type="lists_indexOf">
      <Field name="END">FIRST</Field>
      <Value name="Value">
        <Block type="variables_get">
          <Field name="VAR" id="qGx-H}q0eT6(Fl:iU$Ea">list</Field>
        </Block>
      </Value>
    </Block>
    <Block type="lists_getIndex">
      <Mutation Statement="false" at="true"></Mutation>
      <Field name="MODE">GET</Field>
      <Field name="WHERE">FROM_START</Field>
      <Value name="Value">
        <Block type="variables_get">
          <Field name="VAR" id="qGx-H}q0eT6(Fl:iU$Ea">list</Field>
        </Block>
      </Value>
    </Block>
    <Block type="lists_setIndex">
      <Mutation at="true"></Mutation>
      <Field name="MODE">SET</Field>
      <Field name="WHERE">FROM_START</Field>
      <Value name="LIST">
        <Block type="variables_get">
          <Field name="VAR" id="qGx-H}q0eT6(Fl:iU$Ea">list</Field>
        </Block>
      </Value>
    </Block>
    <Block type="lists_getSublist">
      <Mutation at1="true" at2="true"></Mutation>
      <Field name="WHERE1">FROM_START</Field>
      <Field name="WHERE2">FROM_START</Field>
      <Value name="LIST">
        <Block type="variables_get">
          <Field name="VAR" id="qGx-H}q0eT6(Fl:iU$Ea">list</Field>
        </Block>
      </Value>
    </Block>
    <Block type="lists_split">
      <Mutation mode="SPLIT"></Mutation>
      <Field name="MODE">SPLIT</Field>
      <Value name="DELIM">
        <Shadow type="text">
          <Field name="TEXT">,</Field>
        </Shadow>
      </Value>
    </Block>
    <Block type="lists_sort">
      <Field name="TYPE">NUMERIC</Field>
      <Field name="DIRECTION">1</Field>
    </Block>
  </Category>
  <Separator/>
  <Category name="Variables" colour="#a55b80" custom="VARIABLE"></Category>
  <Category name="Functions" colour="#995ba5" custom="PROCEDURE"></Category>
        <Category name="Other" colour="#997e7e">
          <Block type="take_photo">
            <Statement name="CODE">
              <Block type="variables_set">
                <Field name="VAR">num_photo</Field>
                <Value name="VALUE">
                  <Block type="math_number">
                    <Field name="NUM">0</Field>
                  </Block>
                </Value>
                <Next>
                  <Block type="if_return_error">
                    <Value name="SET_VALUE">
                      <Block type="variables_get">
                        <Field name="VAR">num_photo</Field>
                      </Block>
                    </Value>
                  </Block>
                </Next>
              </Block>
            </Statement>
          </Block>
          <Block type="string_length">
            <Value name="VALUE">
              <Block type="text">
                <Field name="VAR">text</Field>
              </Block>
            </Value>
          </Block>
          <Block type="if_return_error">
            <Value name="SET_VALUE">
              <Block type="variables_get">
                <Field name="VAR">num_photo</Field>
              </Block>
            </Value>
          </Block>
          <Block type="function_flash_photo">
              <Statement name="CODE">
                <Block type="variables_set">
                  <Field name="VAR">flash_photo</Field>
                  <Value name="VALUE">
                    <Block type="math_number">
                      <Field name="NUM">23</Field>
                    </Block>
                  </Value>
                  <Next>
                    <Block type="if_return_error">
                      <Value name="SET_VALUE">
                        <Block type="variables_get">
                          <Field name="VAR">flash_photo</Field>
                        </Block>
                      </Value>
                    </Block>
                  </Next>
                </Block>
              </Statement>
            </Block>
            <Block type = "flash_photo"/>
            <Block type ="make_photo"/>
            <Block type = "robot_move"/>
            <Block type = "robot_take"/>
            <Block type = "robot_release"/>
        </Category>
    </BlocklyComponent>
  );
}


export default App;
