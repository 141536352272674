import React from 'react';
import { 
    Box,
    Typography,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import "./Maima.scss";
import { useStore } from '../hooks/use-store';
import { observer } from "mobx-react-lite";
import { IPicture, IShape } from '../stores/maima-store';
import configFile from '../Config';


function Maima() {
    const maimaStore = useStore().maimaStore;
    const { getPictures, getListOfShapesById } = maimaStore;
    const [selectedPicture, setSelectedPicture] = React.useState<number>(-1);
    const [selectedShape, setSelectedShape] = React.useState<number>(-1);
    const [ pictures, setPicture] = React.useState<IPicture[]>([]);
    const [ shapes, setShapes] = React.useState<IShape[]>([]);
    const [imageBackgroundSrc, setImageBackgroundSrc] = React.useState<string>(``);
    const [imageShapesSrc, setImageShapesdSrc] = React.useState<string>(``);

    React.useEffect(() => {
        if(pictures.length == 0){
            downloadPictures();
        }
    }, [])

    const downloadPictures = async() => {
        let res = await getPictures();
        if (res.length > 0){
            setPicture(res);
            clickTablePictureRow(res[0].id);
        }
    }

    const clickTablePictureRow = async(id: number) =>{
        setImageShapesdSrc(``);
        setSelectedPicture(id);
        setSelectedShape(-1);
        setShapes(await getListOfShapesById(id));
        setImageBackgroundSrc(`${configFile.siteURL}/pictures/${id}/image`);
        setImageShapesdSrc(`${configFile.siteURL}/pictures/${id}/shapes/image`);
    }

    const clickTableShapesRow = async(id: number, pictureId: number) =>{
        setSelectedShape(id);
        setImageShapesdSrc(`${configFile.siteURL}/pictures/${pictureId}/shapes/${id}/image`)
    }

    return(
        <div className="container"> 
            <div className="text-area"><Typography variant='h4'>Recognition results</Typography></div>           
            
            <TableContainer component={Paper} className="red-area">
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Picture's id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {pictures.map((picture: IPicture, idx) => (
                        <TableRow key={idx} onClick={() => clickTablePictureRow(picture.id)} className={selectedPicture === picture.id ? "table-grey" : "table-white"}>
                            <TableCell component="th" scope="row" className='table-row'>{picture.id}   {picture.createdAt}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer component={Paper} className="blue-area">
                <Table aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Shape's id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {shapes.map((shape: IShape, idx) => (
                        <TableRow key={idx} onClick={() => clickTableShapesRow(shape.id, shape.pictureId)} className={selectedShape === shape.id ? "table-grey" : "table-white"}>
                            <TableCell component="th" scope="row" className='table-row'>{shape.id}    {shape.createdAt}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="green-area">
                <img src={imageBackgroundSrc} className='shape-img'/>
                <img src={imageShapesSrc} className='shape-img'/>
            </div>
        </div>
    );
}

export default observer(Maima);