import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet }
    from "react-router-dom";
import Blockly from "./pages/Blockly";
import Maima from "./pages/Maima";
import Main from "./pages/Main";
import Typography from '@mui/material/Typography';
import Directions from "./enums/enum-directions";
import {StoreContext} from './contexts/store-context';
import { store } from "./stores/root-store";

function App() {
    return (
      <StoreContext.Provider value={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />   
            <Route path={Directions.Blockly} element={<Blockly />} />
            <Route path={Directions.Maima} element={<Maima />} />
            <Route path="*" element={<Typography>There's nothing here: 404!</Typography>} />       
          </Routes>
        </Router>
      </StoreContext.Provider>
    );
}
 
export default App;
