import * as Blockly from 'blockly/core';

interface CustomBlock extends Blockly.Block {
  getInputTargetBlock(inputName: string): Blockly.Block | null;
}

Blockly.Blocks['string_length'] = {
  init: function() {
    this.jsonInit({
      "message0": 'length of %1',
      "args0": [
        {
          "type": "input_value",
          "name": "VALUE",
          "check": "String"
        }
      ],
      "output": "Number",
      "colour": 160,
      "tooltip": "Returns number of letters in the provided text.",
      "helpUrl": "http://www.w3schools.com/jsref/jsref_length_string.asp"
    });
  }
}

Blockly.Blocks['take_photo'] = {
  init: function(this: CustomBlock) {
    this.jsonInit({
      "type": "take_photo",
      "message0": "to take photo %1 %2 return number of photo %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_statement",
          "name": "CODE",
          "check": "Number"
        },
        {
          "type": "input_end_row"
        }
      ],
      "colour": 330,
      "tooltip": "Returns number of photo or error.",
      "helpUrl": ""
    });
    this.setOnChange((changeEvent: Blockly.Events.Abstract) => {
      if (changeEvent.type === Blockly.Events.BLOCK_CREATE || changeEvent.type === Blockly.Events.BLOCK_MOVE) {
        const codeBlock = this.getInputTargetBlock('CODE');
        if (codeBlock) {
          makeBlocksNonMovable(codeBlock);
        }
      }
    });
  }
};

Blockly.Blocks['function_flash_photo'] = {
  init: function(this: CustomBlock) {
    this.jsonInit({
      "type": "function_flash_photo",
      "message0": "to flash photo %1 %2 return number of photo %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_statement",
          "name": "CODE",
          "check": "Number"
        },
        {
          "type": "input_end_row"
        }
      ],
      "colour": 100,
      "tooltip": "Returns number of photo with flash or error.",
      "helpUrl": ""
    });
    this.setOnChange((changeEvent: Blockly.Events.Abstract) => {
      if (changeEvent.type === Blockly.Events.BLOCK_CREATE || changeEvent.type === Blockly.Events.BLOCK_MOVE) {
        const codeBlock = this.getInputTargetBlock('CODE');
        if (codeBlock) {
          makeBlocksNonMovable(codeBlock);
        }
      }
    });
  }
};

Blockly.Blocks['if_return_error'] = {
  init: function(this: CustomBlock){
    this.jsonInit({
      "type": "if_return",
      "message0": "if %1 < 0 %2 return - 1 %3",
      "args0": [
      {
        "type": "input_value",
        "name": "SET_VALUE",
        "check": "Number"
      },
      {
        "type": "input_dummy"
      },
      {
        "type": "input_end_row"
      }
      ],
      "previousStatement": null,
      "colour": 210,
      "tooltip": "Check if there is an error",
      "helpUrl": ""
    });
  }
}

Blockly.Blocks['flash_photo'] = {
  init: function(){
    this.jsonInit({    
        "type": "flash_photo",
        "message0": "camera flash",
        "previousStatement": null,
        "nextStatement": null,
        "colour": 30,
        "tooltip": "make photo",
        "helpUrl": ""
    });
  }
}

Blockly.Blocks['make_photo'] = {
  init: function(this: CustomBlock){
    this.jsonInit({
      "type": "make_photo",
      "message0": "make photo %1",
      "args0": [
      {
        "type": "field_dropdown",
        "name": "PHOTO",
        "options": [
          ["0", "0"],
          ["1", "1"]
        ]
      }
      ],
      "output": null,
      "colour": 90,
      "tooltip": "make photo",
      "helpUrl": ""
    });
  }
}

Blockly.Blocks['robot_move'] = {
  init: function(this: CustomBlock){
    this.jsonInit({
      "type": "robot_move",
      "message0": "move robot to x= %1 , y = %2 , z = %3 %4",
      "args0": [
     {
        "type": "field_input",
        "name": "X_COORDINATE",
        "text": "0"
      },
      {
        "type": "field_input",
        "name": "Y_COORDINATE",
        "text": "0"
      },
      {
        "type": "field_input",
        "name": "Z_COORDINATE",
        "text": "0"
      },
      {
        "type": "input_end_row"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "move robot to coordinate(x, y, z)",
      "helpUrl": ""
    });
  }
}

Blockly.Blocks['robot_take'] =  {
  init: function(this: CustomBlock){
    this.jsonInit({
      "type": "robot_take",
      "message0": "robot take %1 %2",
      "args0": [
      {
        "type": "field_variable",
        "name": "NAME",
        "variable": "block"
      },
      {
        "type": "input_end_row"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "take block",
      "helpUrl": ""
    });
  }
}

Blockly.Blocks['robot_release'] = {
  init: function(this: CustomBlock){
    this.jsonInit({
      "type": "robot_release",
      "message0": "release robot %1",
      "args0": [
        {
          "type": "input_end_row"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 330,
      "tooltip": "robot release",
      "helpUrl": ""
    });
  }
}

function makeBlocksNonMovable(block: Blockly.Block): void {
  block.setMovable(false);
  let nextBlock = block.getNextBlock();
  while (nextBlock) {
    nextBlock.setMovable(false);
    nextBlock = nextBlock.getNextBlock();
  }
}
